<template>
    <!-- nav -->
    <nav class="nav">
        <div class="nav__wrap">
            <a class="nav__btn nav__btn--prev">
                <i class="icon icon-left">이전 페이지</i>
            </a>
            <p class="nav__item">
				<span class="nav__text">
					1:1 문의내역
				</span>
            </p>
            <a class="nav__btn nav__btn--map">
            </a>
        </div>
    </nav>
    <!-- //nav-->
    <div id="container" class="container container--fit">
        <div id="sub" class="sub mypage">
            <!-- content -->
            <section class="content">
                <div class="centered centered--xlarge">
                    <div class="centered__wrap">
                        <div class="textbox">
                            <div class="textbox__icon">
                                <img src="@/assets/images/img_warning.png" alt="작성하신 리뷰가 없습니다." style="width:64px">
                            </div>
                            <h3 class="textbox__title">
                                등록된 1:1문의가 없어요
                            </h3>
                        </div>
                    </div>
                </div>
            </section>
            <!-- //content -->
        </div>
    </div>
    <div id="navigation" class="navigation navigation--noborder">
        <div class="btns btns--centered">
            <button type="button" class="btn btn-confirm">문의하기</button>
        </div>
    </div>
</template>